<template>
  <div class=" mx-auto">
    <div>
      <Loading :isLoading="this.isLoading" />
      <div>
        <br>
        <br>
        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
        <div class="row">
            <center>
            <p class="title">
                ¡Se ha referido a su contacto!</p>
            </center>
        </div>
        </div>
        <br>
        <div class="flex" style="margin-top:0px;">
        <div class="w-full">
          <center>
            <p class="description">
              Se envió un mensaje a su contacto <b class="capitalize">{{referenced}}</b>, le mantedremos informado de su avance en el proceso de inscripción.
            </p>
          </center>
        </div>
        </div>
        <br>
        <br>
        <div class="flex-1 bg-gray-0 px-0 py-0 m-0" style="">
        <!-- <h4 style="text-align:center;">Paso 1 de 7</h4> -->
        <div class="row">
            <center>
            <button class="" type="submit, text-decoration: none; width:150px" id="button_popups" v-on:click="redirect('/cuenta/referidos/referir-persona')">Referir a otro contacto</button>
            <br>
            <br>
            <button class="" type="submit, text-decoration: none; width:150px" id="button_popups" v-on:click="redirect('/cuenta/principal')">Ir a la pantalla principal</button>
            </center>
        </div>
        </div>
        <br>
        <br>
    </div>
    </div>

    
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
  </div>
</template>



<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from '@/components/Cuenta/MenuLateral.vue'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
    data() {
      return {
        referenced:"",
        ccapi:process.env.VUE_APP_CCAPI,
      }
    },
    name: 'SuccessfulReferral',
    props: {
        msg: String
    },
    components: {
        MenuLateral
    },
    async beforeMount() {
      await this.loadInfo();
    },
    methods:{
        async loadInfo() {
            var auth = getTokenDecoden();
            this.user_id = auth["id"];

            this.referenced = window.localStorage.getItem("referenced");
        },
        Logout(){
          sessionStorage.clear();
          // window.location.href = "/"
          this.$router.push({path:"/"});
        },
        redirect(url){
          window.localStorage.setItem("referenced", "");
            // window.location.href = url;
          this.$router.push({path:`${ url}`});
        }
    },
}
</script>

<style>

#button_popups {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #feb72b;
  color: #fff;
  font-weight:bold;
  width: 250px;
  height: 40px;
  padding-top: 0%;
  font-size:16px;
}

</style>