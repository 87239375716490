<template>
  <div class="mx-auto menu_principal">
    
      
      <header class=" masthead bg-primary text-white text-center bg-cover header-background">
        

        <div class="container-fluid mx-auto">
          <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
              <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lx:grid-cols-4">
                <div class="row"></div>
                  <div class="row">
                      <!-- <center>
                        <img  src="../../assets/Cuenta/foto-usuario.svg" class="img-responsive" style="border-radius: 50%" height="150px" width="150px"/>
                        <div class="flex justify-between" style="width:155px; visibility:hidden;">
                          <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-2xl lg:text-2xl" style="color: #000;">Seleccionar Imagen</p>
                          <button><img src="../../assets/Cuenta/agregar-imagen.svg" class="img-responsive" height="20px" width="20px" style="margin-top:-5px;"/></button>
                        </div>
                      </center> -->
                  </div>
                  <div class="row" style="text-align:left;margin-top:60px;">
                        <div style="height:60px;color:#000;text-align:left">
                          <p class="title" style="line-height:30px;font-size:33px;font-weight:bold;letter-spacing: 4px;margin-top:0;margin-bottom:0;padding-top:7px;">
                            FONCABSA
                          </p>
                          <div style="font-size:12px;text-align:left;">
                            <p class="subTitle">Sistema Financiero Colaborativo</p>
                          </div>
                        </div>
                 
                        <p class="lg:text-4xl" style="color: #000;margin-top:30px;font-weight: bold;">
                          {{this.primer_nombre+ " " + this.segundo_nombre + " " + this.paterno+" "+ this.materno}}
                        </p>
                        <p style="color: #000;font-size:17px;margin-top:-14px">
                          RFC: {{this.rfc}}
                        </p>
                  </div>
                  <div class="row"></div>
              </div>
          </div>
        </div>

      </header>


    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!-- <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
  </div>
    
</template>






<script>
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
      return {
          errors: [],
          apiToken: null,
          rfc:"",
          primer_nombre:"",
          segundo_nombre:"",
          paterno:"",
          materno:""
        };
      },
  name: 'MenuUsuario',
  props: {
    msg: String
  },
   beforeMount() {
    this.loadInfo();
  },
  methods:{
    loadInfo(){
      var auth = getTokenDecoden()
      this.rfc = auth['rfc'].toUpperCase();
      this.primer_nombre = auth['name'] != null ? auth['name'].toUpperCase() : ""
      this.segundo_nombre = auth['middle_name'] != null ? auth['middle_name'].toUpperCase() : ""
      this.paterno = auth['paternal_name'] != null ? auth['paternal_name'].toUpperCase() : ""
      this.materno = auth['maternal_name'] != null ? auth['maternal_name'].toUpperCase() : ""
    }
  }
}



</script>
  
<style>

  #texto{
    text-decoration-color: white;
  }

  #button_menu{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color:white;
    font-size: 17px;
  }



    h1 { font-size: 5vw; }
    h2 { font-size: 5vw; }
    h3 { font-size: 5vw; }
    h4 { font-size: 5vw; }
    h5 { font-size: 5vw; }

</style>

<style>
.containerr {
  position: relative;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-background {
  background-image:  url('../../assets/imagen_panel_header.png');
  background-repeat: no-repeat;
  background-position: center center;
  height:350px;
}

@media (max-width: 767px) {
  .menu_principal {
    display: none;
  }
}
</style>
