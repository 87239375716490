<template>
<footer class="footer relative mb-0 border-b-2" style="background-color: #726659;">
    <div class="container mx-auto">
        <div class="flex justify-center sm:justify-end -mt-5">
            <div class="hover:text-blue-500"><a href="http://facebook.com" target="_blank"><img src="@/assets/facebook.svg" width="70" height="70" loading="lazy" /></a></div>
            <div><a href="http://instagram.com" target="_blank"><img src="@/assets/instagram.svg" width="70" height="70" loading="lazy" /></a></div>
            <div> <a href="http://twitter.com" target="_blank"><img src="@/assets/twitter.svg" width="70" height="70" loading="lazy" /></a></div>
        </div>
        <div class="flex flex-col text-center text-2xl  text-white font-bold -mt-5 sm:-mt-8">
            <div>
                <a href="/pdf/FONCABSA - Aviso de Privacidad INTEGRAL - 26092023 VF.pdf" target="_blank" style="text-decoration: none; color: white; font-weight: bold;">
                    <p class="hover:text-gray-400" style="font-family:Roboto;">Aviso de privacidad</p>
                </a>
            </div>
            <div class="-mt-4">
                <p class="hover:text-gray-400" style="font-family:Roboto;">Términos y condiciones</p>
            </div>
            <div class="-mt-4">
                <p class="hover:text-gray-400" style="font-family:Roboto;font-weight:300;">FONCABSA © 2020 Derechos Reservados</p>
            </div>
        </div>
    </div>
</footer>
</template>
